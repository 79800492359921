.task {
  padding: 15px 30px;
  transition: 0.1s;
  margin-bottom: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.task:hover {
  background-color: #f2a154;
  color: white;
}

.task svg {
  margin-right: 9px;
}

.task .content {
  width: 100%;
}
